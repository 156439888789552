import * as React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout/index"
import Seo from "../components/seo"
import { StaticImage } from "gatsby-plugin-image"
import * as styles from "../styles/public.module.less"
import UseStore from "../store"
const MainPage = ({ location }) => {
  return (
    <Layout path={location.pathname}>
      <Seo title="前端" />
      <div className={styles.blogStyle}>
        <Link
          to="/tags/react"
          style={{ position: "relative" }}
          onClick={() => {
            UseStore.setState({ knowledgeType: "前端入职培训" })
          }}
        >
          <StaticImage
            layout="constrained"
            formats={["auto", "webp", "avif"]}
            src="../images/1.jpg"
            width={800}
            height={400}
            quality={95}
            alt="Profile picture"
            imgClassName={styles.pic}
          />
          <div className={styles.traineeWord}>
            <p>
              前端 <span /> 入职培训
            </p>
          </div>
        </Link>

        <Link
          to="/tags/node-js"
          style={{ position: "relative" }}
          onClick={() => {
            UseStore.setState({ knowledgeType: "前端知识库" })
          }}
        >
          <StaticImage
            layout="constrained"
            formats={["auto", "webp", "avif"]}
            src="../images/2.png"
            width={400}
            height={400}
            quality={95}
            alt="Profile picture"
            className={styles.picWap1}
            imgClassName={styles.pic}
          />
          <StaticImage
            layout="constrained"
            formats={["auto", "webp", "avif"]}
            src="../images/2.png"
            width={800}
            height={400}
            quality={95}
            className={styles.picWap2}
            imgClassName={styles.pic}
            alt="Profile picture"
          />
          <div className={styles.knowledgeWord}>
            <p>
              前端 <span /> 知识库
            </p>
          </div>
        </Link>
      </div>
    </Layout>
  )
}

export default MainPage

import * as styles from "../layout/index.module.less"
import Head from "../head/index"
import * as React from "react"
const LayoutComponents = ({ children, path }) => {
  return (
    <div className={styles.pageWrapper}>
      <Head path={path} />
      <main className={styles.main}>
        <div>
          {children}
          <footer>
            Copyright © {new Date().getFullYear()} present Powered by
            洪九果品研发团队
          </footer>
        </div>
      </main>
    </div>
  )
}
export default LayoutComponents
